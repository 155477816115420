<template>
  <div id="round-member-tickets" class="container-fluid mb-4 pb-4">
    <div class="page-header mb-1">
      <strong>รายการโพย</strong>
    </div>
    <b-card no-body class="mb-1 position-relative">
      <b-card-header header-tag="nav" class="d-flex justify-content-between">
        <h5 class="card-title mb-0">
          <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
          <span class="text-dark mr-2">เฉพาะงวด</span>
          <span class="text-primary mr-2">{{ roundName }}</span>
          <span class="text-dark mr-2">วันที่</span>
          <span class="text-primary mr-2">{{ roundDate }}</span>
          <small class="text-secondary">(เปลี่ยนได้ที่แถบเมนูด้านบน)</small>
        </h5>
        <button type="button" class="btn btn-outline-info btn-sm" @click="getTickets(false)">Refresh ({{countDown}})</button>
      </b-card-header>

      <b-card-body class="p-2">

        <form class="form-inline p-2" @submit.stop.prevent="searchTickets">
          <label class="mr-2">ค้นหาโพย </label>
          <input type="text" class="form-control mr-sm-2 form-control-sm" placeholder="Username" v-model="username">
          <input type="text" class="form-control mr-sm-2 form-control-sm" placeholder="เลขที่โพย" v-model="ticketNo">
          <select class="form-control mr-sm-2 form-control-sm" v-model="status">
            <option value="">สถานะ</option>
            <option value="Accepted">รับแทง</option>
            <option value="Canceled">ยกเลิก</option>
          </select>
          <button type="submit" class="btn btn-primary mr-sm-2 btn-sm">Submit</button>
        </form>

        <ol v-if="items.length" class="breadcrumb pl-2 pr-0 py-0 m-0 mb-2 border-info d-flex justify-content-between align-items-center">
          <li class="breadcrumb-item py-1">
            <span>แสดง {{ startRow | amountFormat(0) }} - {{ endRow | amountFormat(0) }} จาก {{ totalTicket | amountFormat(0) }} รายการ</span>
          </li>
          <li>
            <ul v-if="pages.length > 1" class="pagination justify-content-end mb-0">
              <li v-if="currentPage > 1" class="page-item" style="cursor: pointer; border-radius: 0;">
                <a class="page-link rounded-0" @click="togglePage(currentPage - 1)"><i class="fas fa-angle-left"></i></a>
              </li>
              <li v-if="currentPage > 3" class="page-item" style="cursor: pointer; border-radius: 0;">
                <a class="page-link rounded-0" @click="togglePage(1)">1</a>
              </li>
              <li v-if="currentPage > 4 && totalPage > 6" class="d-flex align-items-center p-1"><i class="fas fa-ellipsis-h"></i></li>

              <li
                v-for="(page, index) in pages"
                :key="index"
                class="page-item"
                :class="[{'active': page === currentPage}]"
                style="cursor: pointer; border-radius: 0;"
              ><a class="page-link rounded-0" @click="togglePage(page)">{{page}}</a></li>

              <li v-if="currentPage < (totalPage - 3) && totalPage > 6" class="d-flex align-items-center p-1"><i class="fas fa-ellipsis-h"></i></li>
              <li v-if="currentPage < (totalPage - 2)" class="page-item" style="cursor: pointer; border-radius: 0;">
                <a class="page-link rounded-0" @click="togglePage(totalPage)">{{totalPage}}</a>
              </li>
              <li v-if="currentPage < totalPage" class="page-item" style="cursor: pointer; border-radius: 0;">
                <a class="page-link rounded-0" @click="togglePage(currentPage + 1)"><i class="fas fa-angle-right"></i></a>
              </li>
            </ul>
          </li>
        </ol>

        <table class="table table-bordered table-info table-hover mb-0">
          <thead>
            <tr>
              <th width="50">ลำดับ</th>
              <th width="90">เลขที่</th>
              <th width="160" class="pb-1">ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <th width="120">อัตราจ่าย</th>
              <th width="180">เวลา</th>
              <th width="80">รายการ</th>
              <th width="100">ยอดบิล</th>
              <th width="100">ส่วนลด</th>
              <th width="100">สุทธิ</th>
              <th width="120">สถานะ</th>
              <th>หมายเหตุ</th>
              <th width="90"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="item._id"
              :class="[{
                'alert-danger': item.status === 'Canceled',
                'alert-warning': item.status === 'Recoding'
              }]"
            >
              <td class="text-center">{{offset+index+1}}</td>
              <td class="text-center px-0">{{item.ticketNo}}</td>
              <td class="text-center">
                {{ item.account.mainUser.username }}
                <small v-if="showName" class="text-secondary">({{item.account.mainUser.firstName}})</small>
              </td>
              <td class="text-center">{{item.rate.rateTitle}}</td>
              <td class="text-center">
                {{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}
              </td>
              <td class="text-center">{{item.summary.length}}</td>
              <td class="text-right text-success">{{item.summary.amount | amountFormat}}</td>
              <td class="text-right text-danger">{{item.summary.discount | amountFormat}}</td>
              <td class="text-right text-success">{{item.summary.credit | amountFormat}}</td>
              <td class="text-center">
                <TicketStatus :status="item.status" />
              </td>
              <td class="text-center">{{item.remarks}}</td>
              <td class="text-center p-0">
                <button class="btn btn-outline-info btn-sm m-1" title="ดูรายการแทง" @click="viewTicket(item._id)"><i class="fas fa-list"></i></button>
                <button v-if="item.canCancel && permCancel" class="btn btn-danger btn-sm m-1" title="ยกเลิกโพย" @click="cancelTicket(item)"><i class="fas fa-times"></i></button>
              </td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="12" class="text-center alert-warning">กรุณารอซักครู่...</td>
            </tr>
          </tbody>
          <tbody v-if="!isLoading && !this.items.length">
            <tr>
              <td colspan="12" class="text-center">ไม่มีรายการโพย</td>
            </tr>
          </tbody>
        </table>
      </b-card-body>
    </b-card>

    <ViewTicketModal :is-show="isShowTicketModal" :ticket-id="viewTicketId" @close="isShowTicketModal=false" />
  </div>
</template>
<script>
import RoundService from "@/services/RoundService"
import Swal from 'sweetalert2'
import _ from 'lodash'
import moment from '@/helpers/moment'
import ViewTicketModal from '@/views/setting-report/components/ViewTicketModal'
import cryptoRandomString from 'crypto-random-string'
import cAlert from '@/helpers/alert'

export default {
  name: 'RoundMemberTickets',
  components: {
    ViewTicketModal
  },
  data() {
    return {
      data: null,
      isLoading: false,
      showName: false,
      isShowTicketModal: false,
      viewTicketId: null,
      username: '',
      ticketNo: '',
      status: '',
      perPage: 1000,
      currentPage: 1,
      refreshInterval: null,
      countDown: 299
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    items() {
      if(!this.data)
        return []

      return this.data.tickets.map((item)=>{
        item.canCancel = moment().isBefore(this.data.round.roundDate.close) && ['Recoding', 'Accepted'].includes(item.status)
        item.summary = {
          amount: 0,
          credit: 0,
          discount: 0,
          length: 0,
          won: 0,
          ...item.summary
        }
        return item
      })
    },
    totalTicket() {
      return this.data?.totalTicket | 0
    },
    totalPage() {
      return Math.ceil(this.totalTicket/this.perPage)
    },
    pages() {
      let prevPage = this.currentPage - 2
      let nextPage = this.currentPage + 2

      if(prevPage < 1) {
        nextPage = nextPage - prevPage + 1
      }else
      if(nextPage >= this.totalPage) {
        prevPage = prevPage - (nextPage - this.totalPage)
      }

      const startPage = prevPage < 1 ? 1 : prevPage
      const endPage = nextPage > this.totalPage ? this.totalPage : nextPage

      const pages = []
      for(let i=startPage; i<=endPage; i++) {
        pages.push(i)
      }

      return pages
    },
    offset() {
      return (this.currentPage * this.perPage) - this.perPage
    },
    startRow() {
      return this.offset + 1
    },
    endRow() {
      const max = this.startRow + this.perPage - 1
      return max < this.totalTicket ? max : this.totalTicket
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permCancel() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.TicketReport === 'w'
    }
  },
  watch: {
    roundId() {
      if(this.roundId)
        this.getTickets()
    }
  },
  methods: {
    getTickets(silent=false) {

      if(!silent)
        this.isLoading = true

      clearInterval(this.refreshInterval)
      RoundService.getTickets(this.roundId, {
        username: this.username,
        ticketNo: this.ticketNo,
        status: this.status,
        offset: this.offset
      }, silent)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
        this.countDownRefresh()
      })
    },
    viewTicket(ticketId) {
      this.isShowTicketModal = true
      this.viewTicketId = ticketId
    },
    cancelTicket(ticket) {
      if(!this.data)
        return

      if(!['Recoding', 'Accepted'].includes(ticket.status))
        return Swal.fire({
          icon: 'error',
          text: 'ไม่สามารถยกเลิกโพยได้'
        })

      if(moment().isAfter(this.data.round.roundDate.close))
        return Swal.fire({
          icon: 'error',
          title: 'หวยปิดรับแล้ว',
          text: 'ไม่สามารถยกเลิกโพยได้'
        })

      const uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
      console.log(ticket)
      Swal.fire({
        title: '<span class="text-danger">ยืนยันยกเลิกโพย!</span>',
        html: `<h4>สมาชิก <span class="text-primary">${ticket.account.mainUser.username}</span><br>รายการแทง <span class="text-primary">${ticket.summary.length}</span> รายการ<br>ยอดรวม <span class="text-primary">${ticket.summary.amount}</span> บาท</h4>`,
        icon: 'warning',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#dc3545',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn-danger'
        }
      })
      .then((res)=>{
        if(res.isConfirmed) {
          this.isLoading = true
          RoundService.cancelTicket(ticket._id, {uniqueCode}).then((response)=>{
            if(response.success) {
              this.$notify({
                type: 'success',
                title: 'ยกเลิกโพยแล้ว!'
              })
            }else{
              throw new Error(response?.message)
            }
          })
          .catch((e)=>{
            cAlert({
              ...e,
              title: 'ผิดพลาด!',
              text: e?.message || 'ยกเลิกโพยไม่สำเร็จ',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
          .finally(()=>{
            this.isLoading = false
            this.getTickets()
          })
        }
      })
    },
    togglePage(page) {
      this.currentPage = page
      this.getTickets()
    },
    searchTickets() {
      this.currentPage = 1
      this.getTickets()
    },
    countDownRefresh() {
      this.countDown = 299
      clearInterval(this.refreshInterval)
      this.refreshInterval = setInterval(()=>{
        this.countDown--
        if(this.countDown < 1) {
          this.getTickets(true)
        }
      }, 1000)
    }
  },
  mounted() {
    if(this.roundId)
      this.getTickets()
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: 500;
        line-height: 1;
        font-size: 85%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 85%;
      }
      th {
        padding: 5px;
        font-size: 85%;
      }
    }
  }
  tfoot {
    tr {
      td {
        font-size: 85%;
      }
    }
  }
}
</style>
